var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("Layout", [
    _c("div", { staticClass: "row justify-content-center" }, [
      _c("div", { staticClass: "col-md-8 col-lg-6 col-xl-5" }, [
        _c("div", { staticClass: "card overflow-hidden" }, [
          _c("div", { staticClass: "bg-gradient-primary" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-7" }, [
                _c("div", { staticClass: "text-white p-4" }, [
                  _c("h5", { staticClass: "text-white" }, [
                    _vm._v(_vm._s(_vm.$t("welcome.header.title"))),
                  ]),
                  _c("p", [
                    _vm._v(_vm._s(_vm.$t("welcome.header.description"))),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "col-5 align-self-end" }, [
                _c("img", {
                  staticClass: "img-fluid",
                  attrs: {
                    src: require("@/assets/images/welcome-image.png"),
                    alt: "",
                  },
                }),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "card-body pt-0" }, [
            _c("div", [
              _c("div", { staticClass: "avatar-md profile-user-wid mb-4" }, [
                _c(
                  "span",
                  { staticClass: "avatar-title rounded-circle bg-light" },
                  [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/images/Redem Icon.png"),
                        alt: "",
                        height: "38",
                      },
                    }),
                  ]
                ),
              ]),
            ]),
            _c("div", { staticClass: "mt-4" }, [
              _vm._v(" " + _vm._s(_vm.$t("welcome.description1")) + " "),
            ]),
            _c("div", { staticClass: "mt-3" }, [
              _vm._v(" " + _vm._s(_vm.$t("welcome.description2")) + " "),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "mt-5 text-center" }, [
          _c("p", [
            _vm._v(
              " © " +
                _vm._s(new Date().getFullYear()) +
                " Redem GmbH | made with "
            ),
            _c("i", { staticClass: "mdi mdi-heart text-danger" }),
            _vm._v(" in Austria "),
          ]),
        ]),
        _c("div", { staticClass: "mt-3 text-center" }, [
          _c(
            "span",
            {
              staticClass: "mr-2",
              staticStyle: { cursor: "pointer" },
              on: {
                click: function ($event) {
                  return _vm.setLanguage(_vm.languages[0].language)
                },
              },
            },
            [
              _c("img", {
                staticClass: "mr-1",
                attrs: { src: _vm.languages[0].flag, height: "10" },
              }),
              _vm._v(" " + _vm._s(_vm.languages[0].title) + " "),
            ]
          ),
          _vm._v(" • "),
          _c(
            "span",
            {
              staticClass: "ml-2",
              staticStyle: { cursor: "pointer" },
              on: {
                click: function ($event) {
                  return _vm.setLanguage(_vm.languages[1].language)
                },
              },
            },
            [
              _c("img", {
                staticClass: "mr-1",
                attrs: { src: _vm.languages[1].flag, height: "10" },
              }),
              _vm._v(" " + _vm._s(_vm.languages[1].title) + " "),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }