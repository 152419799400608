<script>
import Layout from "@/router/layouts/auth";
import appConfig from "@/app.config";
import i18n from "@/i18n";

/**
 * Register component
 */
export default {
  page: {
    title: "Welcome",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout },
  data() {
    return {
      languages: [
        {
          flag: require("@/assets/images/flags/us.jpg"),
          language: "en",
          title: "English"
        },
        {
          flag: require("@/assets/images/flags/germany.jpg"),
          language: "de",
          title: "Deutsch"
        }
      ],
      lan: i18n.locale
    };
  },
  methods: {
    setLanguage(locale) {
      this.lan = locale;
      i18n.locale = locale;
      window.localStorage.setItem("locale", locale);
    }
  }
};
</script>

<template>
  <Layout>
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6 col-xl-5">
        <div class="card overflow-hidden">
          <div class="bg-gradient-primary">
            <div class="row">
              <div class="col-7">
                <div class="text-white p-4">
                  <h5 class="text-white">{{ $t("welcome.header.title") }}</h5>
                  <p>{{ $t("welcome.header.description") }}</p>
                </div>
              </div>
              <div class="col-5 align-self-end">
                <img
                  src="@/assets/images/welcome-image.png"
                  alt
                  class="img-fluid"
                />
              </div>
            </div>
          </div>
          <div class="card-body pt-0">
            <div>
              <div class="avatar-md profile-user-wid mb-4">
                <span class="avatar-title rounded-circle bg-light">
                  <img src="@/assets/images/Redem Icon.png" alt height="38" />
                </span>
              </div>
            </div>

            <div class="mt-4">
              {{ $t("welcome.description1") }}
            </div>
            <div class="mt-3">
              {{ $t("welcome.description2") }}
            </div>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->
        <div class="mt-5 text-center">
          <p>
            © {{ new Date().getFullYear() }} Redem GmbH | made with
            <i class="mdi mdi-heart text-danger"></i> in Austria
          </p>
        </div>

        <!-- language Selector -->
        <div class="mt-3 text-center">
          <span
            class="mr-2"
            @click="setLanguage(languages[0].language)"
            style="cursor: pointer"
          >
            <img class="mr-1" :src="languages[0].flag" height="10" />
            {{ languages[0].title }}
          </span>
          &bull;
          <span
            class="ml-2"
            @click="setLanguage(languages[1].language)"
            style="cursor: pointer"
          >
            <img class="mr-1" :src="languages[1].flag" height="10" />
            {{ languages[1].title }}
          </span>
        </div>
      </div>

      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>

<style lang="scss" module></style>
